/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // Nav Toggle
        $(document).ready(function() {

          $('.nav-resp').hide();

          $('.burger').click(function(){
            $(this).addClass('on');
            $('.nav-resp').slideToggle(500);
          });


          $('.nav .menu-item-has-children .sub-menu').hide();

          // Remove href attribute from top level nav items if they have a sub menu 
          $('.nav .menu-item-has-children > a').removeAttr('href');

          $('.nav .menu-item-has-children > *').on("click", function(){

            // Close any open sub menus
            $(this).parent().siblings().find('.sub-menu.on').slideUp(300);
            
            // Remove .open class from other top level items
            $(this).parent().siblings().removeClass('open');

            // Add .open class to clicked item           
            $(this).parent().toggleClass('open');

            // Toggle sub menu of clicked item
            $(this).siblings('.sub-menu').addClass('on').slideToggle(300);
          
          });



        });

        // // RESPONSIVE NAVIGATION
        // jQuery(window).ready(function() {

        //   $('.nav-resp').hide();

        //   $('.burger').click(function(){
        //     $('.nav-toggle').toggleClass('fixed');
        //     $('.nav-resp').slideToggle(500);
        //   });

        // });

        // Flexslider
        $(document).ready(function() {
          $('.flexslider').flexslider({
            controlNav: false,
            directionNav: true, 
          });
        });

        // Add class to testimonial column
        $( '.grey-col > h2:contains("Testimonial")').parent().addClass("testimonial");

        $(document).ready(function(){
          $( ".accordion-title" ).click(function() {
            $(this).text(function(i, text){
                return text === "View Less" ? "View More" : "View Less";
            });
          });
        });

        // Init testimonial carousel
        $(document).ready(function(){

          $('.testimonial-carousel').slick({
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: 2,
            slidesToScroll: 2,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,

                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
          });

          $( ".read-more-button" ).click(function() {

            $( this ).prev().toggleClass( "show" );

            $(this).text(function(i, text){
                return text === "Close" ? "Read More" : "Close";
            });
          });
        });

        // Init Process Carousel
        $(document).ready(function(){

          $('.process-carousel').slick({
            dots: true,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,

                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
          });

        });

        // Accordion
        $(document).ready(function(){
          $( ".accordion-title" ).click(function() {
            $(this).text(function(i, text){
                return text === "View Less" ? "View More" : "View Less";
            });
          });
        });
        
        // Popup triggering
        $(document).ready(function(){
          $('a[href^="#popup"], .js-popup-trigger').magnificPopup({
            type: 'inline',
            midClick: true
          });
        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'design_build': {
      init: function() {
      }
    },
    'projects': {
      init: function() {        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_m_sky': {
      init: function() {
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
